@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-VariableFont_opsz\,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-VariableFont_opsz\,wght.ttf") format("truetype");
}

html,body{
  scroll-behavior: smooth;
}